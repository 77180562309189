/* Botão de abrir o chat */
.chatbot-toggle-button {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #FFFFFF;
    border: none;
    position: fixed;
    bottom: 20px;
    right: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
}

/* Chatbot aparece quando isChatOpen é verdadeiro */
.chatbot-container.open {
    display: flex;
}

/* Botão para fechar o chatbot */
.close-chatbot {
    background: none;
    border: none;
    font-size: 20px;
    color: #333;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
}

.hide-chatbot {
    background: none;
    border: none;
    font-size: 20px;
    color: #333;
    cursor: pointer;
    position: absolute;
    right: 35px;
    top: 10px;
}

/* Posicionamento do chatbot */
.chatbot-container {
    width: 400px;
    height: 560px;
    border-radius: 16px;
    box-shadow: 0 2px 4px #6161611A;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    font-family: 'Vazirmatn', sans-serif !important;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 9999;
}

.chatbot-header {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fff;
    padding: 15px;
    text-align: start;
    font-weight: bold;
    box-shadow: 0 2px 4px #6161611A;
    gap: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-top-right-radius: 16px !important;
    border-top-left-radius: 16px !important;
    border: none !important;
}

.chatbot-title {
    font-family: 'Vazirmatn', sans-serif !important;
    font-size: 16px;
    font-weight: bold;
    color: #3A344C;
}

.chatbot-context {
    font-family: 'Vazirmatn', sans-serif !important;
    font-size: 14px;
    font-weight: 300;
    color: #6C6C6C;
}

.chatbot-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 380px !important;
}

.chatbot-content .menu {
    justify-content: center;
}

.assistant-name {
    font-size: 18px;
    color: #333;
}

.chatbot-input {
    width: 100%;
    display: flex;
    height: 45px !important;
    align-items: center;
    justify-content: center;
    padding: 15px;
}

.chatbot-content .input-box {
    height: 60px !important;
    display: flex;
}

.message {
    margin-bottom: 0.625rem;
    border-radius: 0.625rem;
    max-width: 100%;
    word-wrap: break-word;
}

.chatbot-window {
    margin-bottom: 6rem;
    flex: 1;
    padding: 0.625rem;
    overflow-y: auto !important;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    width: 1400px;
    max-height: 100%;
    min-height: 40%;
    scrollbar-width: none;
    overflow: auto;
}

.chatbot-window.chatbot-chat {
    margin-top: 5px;
    margin-bottom: 2rem;
    width: 90% !important;
    height: 380px !important;
}

.chatnot-window::-webkit-scrollbar {
    display: none;
}

textarea::placeholder {
    font-size: 15px !important;
}

.feedback-options-container.card {
    height: 300px !important;
}

.options {
    flex-direction: column !important;
}

.assistant-text-message {
    margin-top: 15px !important;
}