/* Geral */
.patient-dashboard-main-content {
    margin: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    align-items: flex-start;
    overflow-y: auto;
    min-height: 100vh; 
    max-height: 100vh;
  }
  
  .patient-dashboard-main-content .card {
    background-color: #fff;
    border-radius: 0.625rem;
    border-color: #DCDCDC;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  
  /* Títulos */
  .patient-dashboard-title-container {
    margin-left: 15px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .patient-dashboard-title-container .user-dropdown{
    margin-top: 0;
    margin-right: 30px;
  }
  .patient-dashboard-title-container h3{
    margin-top: 15px;
  }
  
  .patient-dashboard-title {
    font-size: 1.5rem;
    font-weight: 700;
  }
  
  /* Gráficos */
  .patient-dashboard-charts {
    display: grid;
    grid-template-areas:
      "mostCommonGraph timeGraph quantityGraph";
    grid-template-columns: 2fr 2fr 1fr;
    gap: 20px;
    padding: 10px;
    width: 100%;
  }
  
  .patient-dashboard-charts > .patient-most-common-graph-card {
    grid-area: mostCommonGraph;
    height: 420px !important;
    width: 100% !important;
  }
  
  
  .patient-dashboard-charts > .patient-most-common-graph-card .card {
    grid-area: mostCommonGraph;
    height: 420px !important;
    width: 100% !important;
  }
  
  .patient-dashboard-charts > .patient-quantity-graph-card {
    grid-area: quantityGraph;
    display: flex;
    justify-content: space-between;
    gap: 15px;
  }
  
  .patient-dashboard-charts > .patient-quantity-graph-card > .card{
    height: 210px !important;
    width: 100% !important;
  }

  .card-chart-title {
    font-size: 18px !important;
  }

  .card-chart {
    gap: 5px !important;
  }
  
  .patient-dashboard-charts > .patient-time-graph-card {
    grid-area: timeGraph;
    height: 420px !important;
    width: 100% !important;
  }
  
  /* Títulos dos Gráficos */
  .patient-graph-title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }
  
  .patient-graph-title {
    font-family: 'Vazirmatn', sans-serif;
    color: #080808;
    font-weight: 600;
  }
  
  @media (max-width: 1200px) {
    .patient-dashboard-charts {
      grid-template-areas:
        "mostCommonGraph"
        "quantityGraph"
        "timeGraph";
      grid-template-columns: 1fr;
    }
  }
  
  @media (max-width: 768px) {
    .patient-dashboard-title {
      font-size: 1.2rem;
    }
    .graph-title {
      font-size: 1rem;
    }
    .patient-dashboard-charts >   .time-graph-card {
      height: 520px !important;
      margin: 10px 0;
    }
  }
  
  @media (max-width: 480px) {
    .patient-dashboard-title {
      font-size: 16px;
    }
    .patient-graph-title {
      font-size: 0.9rem;
    }
    .patient-card-chart-title {
      font-size: 16px;
    }
    .patient-card-chart-value {
      font-size: 26px;
    }
    .patient-card-chart-subtitle {
     font-size: 14px;
    }
  }

  