.user-dropdown {
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: end;
    margin-top: 15px;
    margin-right: 15px;
    z-index: 9999;
}

.user-info {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.user-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
}

.user-details {
    display: flex;
    flex-direction: column;
    margin: 0.5rem;
}
.toggle {
    background: none;
    border: none;
    font-size: 14px;
    cursor: pointer;
    margin-left: 5px;
}

.dropdown-user-menu {
    top: 55px;
    list-style: none;
    padding: 5px 0;
    width: 120px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.dropdown-user-menu li {
    padding: 10px;
}

.dropdown-user-menu li a {
    text-decoration: none;
    color: black;
    display: block;
}

.dropdown-user-menu li:hover {
    background: #f0f0f0;
}

.user-name {
    font-size: 14px !important;
}

.user-email {
    font-size: 14px !important;
}

.user-avatar-circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #0F5894;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
}

@media (max-width: 1200px) {
    .user-dropdown {
        margin-right: 80px;
    }
}

@media (max-width: 1000px) {
    .user-dropdown {
        margin-right: 60px;
    }
}

@media (max-width: 800px) {
    .user-dropdown {
        position: relative;
        display: flex;
        padding: 0;
        margin: 0 !important;
    }
}
.ellipsis-text {
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.arrow{
    background: none;
    border: none;
}
.dropdown-menu-user {
    display: block;
    position: absolute;
    top: 70px;
    background: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    width: 12rem;
    z-index: 999999999999;
    position: absolute;
    transition: opacity 0.2s ease-in-out;
}
.dropdown-options a,
.dropdown-options button {
    color: #000000;
    text-decoration: none;
}

.dropdown-options button {
    background: none;
    border: none;
    width: 100%;
    text-align: left;
    cursor: pointer;
    padding: 8px 12px;
}

.dropdown-options li:hover {
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 4px;
}
.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
}
.user-dropdown.inside-context-box {
    margin-right: 0 !important;
}
