.context-icon-conteiner {
    display: flex;
    align-items: center !important;
    justify-content: flex-start !important;
    width: 380px !important;
    max-width: 380px !important;
    height: 55px !important;
    gap: 10px;
    cursor: pointer;
    padding: 10px;
    margin-left: 10px;
    margin-right: 10px;
}

.context-icon-title {
    font-family: 'Vazirmatn', sans-serif !important;
    font-size: 16px;
    font-weight: bold;
    color: #3A344C;
}

.context-icon-subtitle {
    font-family: 'Vazirmatn', sans-serif !important;
    font-size: 14px;
    font-weight: 300;
    color: #6C6C6C;
}

.context-icon-img-container {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background-color: #EDEEFF !important;
    color: #15589A !important;
    width: 45px !important;
    height: 45px !important;
}

.context-icon-container img {
    width: 25px !important;
    height: 25px !important;
}

.context-icon-conteiner:hover {
    background-color: #EDEEFF !important;
}

.context-icon-conteiner:hover .context-icon-img-container{
    background-color: #15589A !important;
    color: #EDEEFF !important;
}

.context-icon-conteiner:hover svg {
    color: #EDEEFF !important;
}

.white-img {
    display: none;
}

.context-icon-conteiner:hover .dark-img {
    display: none !important;
}

.context-icon-conteiner:hover .white-img {
    display: block;
}

