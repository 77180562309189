.chat-item {
  display: flex;
  align-items: center; 
  width: 100%;
  margin-bottom: 10px;
}

.user-chat-item {
  flex-direction: row-reverse;
}

.assistant-chat-item {
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
}

.chat-icon {
  margin: 0 15px;
  display: flex;
  align-items: center; 
}

.message {
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  text-align: justify; 
  max-width: 730px !important; 
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: break-word;
}

.assistant-message {
  background-color: #7DB1CE1A;
}

.assistant-text-message {
  margin-top: 10px;
  padding-right: 40px;
}