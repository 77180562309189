.user-table-content {
    display: flex;
    flex-direction: column;
    height: 100vh;
    align-items: flex-start;
    margin: 1rem;
}

.user-table-title {
    font-size: 1.5rem;
    line-height: 31px;
    font-weight: 700;
}

.user-list-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 10px 10px 20px 0;
}

.user-list-content .user-dropdown{
    margin-top: 0;
    margin-right: 20px;
}

.user-list-content p{
    margin-top: 25px;
}

.user-btn{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
    padding-right: 40px;
    padding-bottom: 20px;
}

table {
    border-collapse: collapse !important;
}

th, td {
    vertical-align: middle;
    height: 55px;
    border-top: 1px solid rgba(194, 190, 190, 0.907) !important;
    border-bottom: 1px solid rgba(194, 190, 190, 0.907) !important;
    border-left: none !important;
    border-right: none !important;
}

.table-header th {
    color: #555555 !important;
    font-weight: 600;
    padding: 12px 16px;
    background-color: #F0F0F0;
}

.surgeries-table-body td {
    padding: 16px;
    font-size: 14px;
    color: #383838 !important;
}

.user-table-body td {
    padding: 16px;
    font-size: 14px;
    color: #383838 !important;
}

.user-list-item {
    cursor: pointer;
    background-color: #FFFFFF;
}

.user-list-item:hover {
    border: 2px solid #5EA9FF;
}

.button-ponto {
    background: none;
    border: none;
    font-weight: bold;
}
.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
}
.btn-primary{
    border: none;
    width: auto !important;
}