.feedback-icons-container {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.feedback-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.feedback-options-container {
    height: 100%;
    width: 100%;
    flex-direction: row;
    font-size: 14.4px !important;
}

.feedback-options-container.closed {
    display: none;
}

.feedback-options-container.open {
    display: flex;
}

.feedback-options-container.card {
    margin-top: 10px;
    width: 100% !important;
    height: 120px !important;
    align-items: center !important;
    justify-content: flex-start !important;
    gap: 3px !important;
    padding: 20px;
    background-color: transparent;
}

.feedback-options-container-title {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
}

.feedback-icon {
    cursor: pointer;
    color: #A0A0A0;
    margin-left: 10px;
    scale: 0.8;
    transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
}

.feedback-icon.copied {
    transform: scale(1.2);
    opacity: 0.8;
    color: #0C3760;
}

.options {
    width: 100%;
    display: flex;
    flex-direction: row;
    color: #A0A0A0;
    align-items: flex-start;
    justify-content: flex-start;
}

.option {
    margin: 5px;
    border: 1px solid #A0A0A0 !important;
    padding: 4px 8px;
    border-radius: 4px;
    cursor: pointer;
}

.option.selected {
    background-color: #A0A0A0;
    color: white;
}

.custom-modal .modal-content {
    font-family: 'Vazirmatn', sans-serif;
}

.custom-modal .modal-title {
    font-size: 16px;
}

.custom-modal .modal-footer {
    display: flex;
    justify-content: space-between;
}

.btn.modal-button {
    background-color: #A0A0A0 !important;
    border: none;
    color: white;
    padding: 8px 16px;
    border-radius: 4px;
    transition: background-color 0.3s ease;
    margin: 3px;
    width: 100px !important;
}

.btn.modal-button.send-btn {
    background-color: #1588C9 !important;
}

.modal-button:hover {
    background-color: #909090 !important;
}

.modal-button.send-btn:hover {
    background-color: #0881c7 !important;
}

.option:hover {
    background-color: #909090;
    color: white;
}

.modal-button-container {
    display: flex;
    width: 100%;
    align-items: flex-end;
    justify-content: flex-end;
}

.modal {
    position: fixed !important;
    top: 33% !important;
}


.success-message {
    background-color: #28a745;
    color: white;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    margin-top: 10px;
    font-weight: bold;
}

.success-animation {
    animation: scaleUp 0.5s ease-out;
}

@keyframes scaleUp {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}



@media (max-width: 1000px) {
    .feedback-options-container.card {
        height: 130px !important;
    }
}

@media (max-width: 800px) {
    .feedback-options-container.card {
        height: 170px !important;
    }
}

@media (max-width: 700px) {
    .feedback-options-container.card {
        height: 300px !important;
    }

    .options {
        flex-direction: column !important;
    }
}