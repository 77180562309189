.card-pagination {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 1.25rem;
}

.pagination-info {
    color: #4b5563;
    font-size: 0.875rem;
    margin-bottom: 10px;
}

.pagination-container {
    display: flex;
    align-items: center;
}

.pagination {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.page-item {
    margin: 0 2px;
}

.page-link {
    display: inline-block;
    padding: 6px 12px;
    font-size: 0.875rem;
    font-weight: 500;
    color: #4b5563;
    text-align: center;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #fff;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.page-link:hover {
    background-color: #f3f4f6;
}

.page-link:disabled {
    background-color: #f9fafb;
    color: #d1d5db;
    cursor: not-allowed;
}

.page-item.disabled .page-link {
    pointer-events: none;
    cursor: not-allowed;
}

.page-link.active {
    background-color: #2563eb;
    color: white;
    border-color: #2563eb;
}

.page-item:first-child .page-link {
    border-radius: 4px 0 0 4px;
}

.page-item:last-child .page-link {
    border-radius: 0 4px 4px 0;
}
