form {
    width: 90% !important;
}

.input-box-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100% !important;
    height: 45px !important;
    gap: 5px;
}

.chat-input-box {
    margin-bottom: 80px;
}

.chatbot-content .input-container {
    flex: 1;
    display: flex;
    align-items: center;
    border-radius: 4px !important;
    border: 1px solid #ABABAB;
    padding: 10px;
    background-color: #FFFFFF;
    width: 95% !important;
    height: 25px !important;
}

.input-container textarea {
    height: 35px !important;
    flex: 1;
    padding: 0;
    font-size: inherit;
    font-family: inherit;
    border: none;
    border-radius: 0;
    outline: none;
    resize: none;
    box-shadow: none !important;
    color: #3A344C;
    font-family: 'Vazirmatn', sans-serif !important;
    font-size: 14px;
}

textarea::placeholder {
    font-size: 15px !important;
}

.send-button {
    width: 45px !important;
    height: 45px !important;
    border: none;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color:#15589A !important;
}