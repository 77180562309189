.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-image: url('../images/main-background.jpg');
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat; 
  position: relative;
}

.welcome-msg {
  font-size: 20px;
  font-weight: 700;
  font-family: 'Vazirmatn', sans-serif;
  margin: 0;
  padding: 0;
}

.form-msg {
  font-size: 14px;
  font-weight: 400;
  font-family: 'Vazirmatn', sans-serif;
  margin: 0;
  padding: 0;
}

.card {
  border-radius: 16px !important;
  width: 550px !important;
  height: 520px !important; 
  padding: 20px; 
  gap: 16px; 
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center; 
}

.form {
  width: 475px !important;
}

.form-control {
  border-radius: 4px !important;
  max-height: 50px;
  padding: 16px;
  border-radius: 4px !important;
  width: 100%; 
  max-width: 100%; 
}

input[type="date"].form-control{
  max-height: 38px; 
}

.assistant-logo {
  width: 50px;
  height: 50px;
  max-width: 100%; 
  border: 1px solid #C3C3C3;
  border-radius: 10px;
  padding: 8px;
}

.label {
  color: #4A4A4A !important;
  font-size: 14px !important;
  font-weight: 400;
}

.btn {
  width: 100%;
  background-color: #1588C9 !important;
  color: white !important;
}

.forgot-password {
  text-decoration: underline !important;
  color: #095f91;
  font-size: 13px;
}

.new-user-link {
  text-decoration: underline !important;
  color: #1588C9;
  font-weight: 700;
}

.error-message {
  font-size: 14px !important;
  font-family: 'Vazirmatn', sans-serif !important;
  margin-top: 3px !important;
}


@media screen and (max-width: 800px) {
  .card {
    width: 80% !important; 
    height: auto !important; 
    padding: 16px;
  }

  .form {
    width: 90% !important; 
  }

  .assistant-logo {
    width: 40px;
    height: 40px;
    padding: 6px;
  }

  .welcome-msg {
    font-size: 18px;
  }

  .form-msg, .label {
    font-size: 12px !important;
  }
}

@media screen and (max-width: 600px) {
  .card {
    width: 90% !important; 
    height: auto !important;
    padding: 12px;
  }

  .form {
    width: 95% !important;
  }

  .assistant-logo {
    width: 35px;
    height: 35px;
    padding: 4px;
  }

  .welcome-msg {
    font-size: 16px;
  }

  .form-msg {
    font-size: 12px;
  }

  .btn {
    font-size: 12px !important;
  }
}

.form-control .MuiOutlinedInput-root {
  max-height: 50px;
  padding: 16px;
}

@media screen and (max-width: 800px) {
  .form-control .MuiOutlinedInput-root {
    max-height: 45px;
    padding: 10px;
  }

  .form-control .MuiOutlinedInput-root input::placeholder {
    font-size: 14px; 
  }
}

@media screen and (max-width: 600px) {
  .form-control .MuiOutlinedInput-root {
    max-height: 40px;
    padding: 8px;
  }

  .form-control .MuiOutlinedInput-root input::placeholder {
    font-size: 12px; 
  }
}

