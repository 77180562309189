.app {
  display: flex;
  height: 100vh;
  margin: 0;
  justify-content: flex-end;
}

.main-content {
  flex-grow: 1; 
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: auto;
  background-color: #FAFAFA;
  font-family: 'Vazirmatn', sans-serif;
  transition: all 0.3s ease; 
}

@media (max-width: 1100px) {
  .sidebar {
    z-index: 1000;
    top: 0;
    left: 0;
    height: 100vh;
    transition: width 0.3s ease;
    width: 60px !important;
    min-width: 60px !important;
  }

  .sidebar.expanded {
    width: 300px !important;
    min-width: 250px !important;
  }
}

@media (max-width: 600px) {
  .sidebar {
    width: 60px !important;
    min-width: 60px !important;

  }

  .sidebar.expanded {
    width: 220px !important;
    min-width: 220px !important;

  }
  
  .main-content {
    transition: opacity 0.3s ease;
  }

  .sidebar.expanded ~ .main-content {
    opacity: 0.3;
  }
}