.register-container{
    margin: 1rem;
}
.register-title-container {
    margin-left: 15px;
    margin-top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.register-title-container .user-dropdown{
    margin-right: 30px;
}
.register-title-container p{
    margin-top: 15px;
}
.error-message {
    color: red !important;
    font-size: 14px !important;
    margin-top: 4px !important;
}
.btn-back {
    background-color: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #ccc;
    color: #474D48;
    font-weight: 600;
    padding: 8px 12px;
}

.btn-back:hover {
    color: #FFFFFF;
    border-color: #474D48;
    background-color: #474D48;
}

.btn-cadastrar {
    background-color: #154ec9;
    color: white;
    border-radius: 4px;
    border: none;
    text-transform: none;
    font-weight: bold;
    padding: 8px 12px;
}

.btn-cadastrar:hover {
    background-color: #0f3ca3;
}
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
    color: #383838 !important;
}
.alert-custom {
    background-color: rgba(39, 120, 214, 0.1);
    color: black;
    font-size: 12px;
}


.alert-icon {
    color: #5B85D2;
    font-size: 16px;
}

.alert-text {
    display: flex;
    align-items: center;
}

.alert-container {
    margin-top: 0.5rem;
    padding: 0.5rem;
    border-radius: 0.375rem;
    display: flex;
}
.user-action-btn {
    background-color: #0F5894;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 0.4rem 0.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background 0.3s ease-in-out;
}

.user-action-btn:hover {
    background-color: #084069;
}
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
    padding: 0 !important;
}
.form {
    width: 100% !important;
}